import styled, { css } from "styled-components";
import { breakpoints } from "@tvg/design-system";

export const CardWrapper = styled.div<{
  isFDR: boolean;
  isOnHomePage: boolean;
}>`
  display: flex;
  flex-direction: column;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colorTokens.border.default};
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colorTokens.background.surface};
  ${({ isOnHomePage }) =>
    isOnHomePage
      ? css`
          width: 96%;
          height: 302px;
          border-radius: 4px;
        `
      : css`
          max-width: 351px;
          width: 96%;
          height: 282px;
        `}

  @media screen and ${breakpoints.desktop.min.sm} {
    width: 335px;
  }

  ${({ isFDR }) =>
    isFDR &&
    css`
      flex-shrink: 0;
      scroll-snap-stop: always;
      scroll-snap-align: start;

      @media screen and ${breakpoints.tablet.max.sm} {
        &:only-child {
          width: 100%;
        }
      }
    `}
`;

export const CardHeader = styled.div<{
  isOnHomePage?: boolean;
  isFDTVPage?: boolean;
}>`
  height: 64px;
  padding: ${({ theme }) => `10px 0 10px ${theme.space["space-4"]}`};
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colorTokens.background.layer};
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colorTokens.border.default};
  ${({ isOnHomePage, isFDTVPage, theme }) =>
    isOnHomePage || isFDTVPage
      ? css`
          border-radius: 4px 4px 0 0;

          &:hover {
            cursor: pointer;
            background-color: ${theme.colorTokens.background.hover};
          }
        `
      : css`
          border-radius: 2px;
        `}
`;

export const AvatarWrapper = styled.div`
  border-radius: 50%;
  overflow: hidden;
`;

export const SummaryInfo = styled.div`
  margin-left: ${({ theme }) => theme.space["space-3"]};
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

export const TalentSummaryInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const RaceSummaryInfo = styled.div<{ hidden: boolean }>`
  display: ${({ hidden }) => (hidden ? "none" : "flex")};
  flex-direction: column;
  justify-content: center;

  @media screen and ${breakpoints.tablet.max.sm} {
    display: none;
  }
`;

export const CardBody = styled.div`
  max-height: 150px;
  margin-right: ${({ theme }) => theme.space["space-4"]};
  overflow: auto;
`;

export const ButtonWrapper = styled.div<{
  isOnHomePage?: boolean;
  isViewRaceBtn?: boolean;
  isBettingBtn?: boolean;
}>`
  ${({ isOnHomePage, isViewRaceBtn }) =>
    isOnHomePage &&
    isViewRaceBtn &&
    css`
      width: 35%;
    `}
  ${({ isOnHomePage, isBettingBtn }) =>
    isOnHomePage &&
    isBettingBtn &&
    css`
      width: 62%;
    `}
  display: flex;
`;

export const ActionsWrapper = styled.div<{
  isOnHomePage: boolean;
}>`
  width: 100%;
  display: flex;
  margin-top: auto;
  ${({ isOnHomePage, theme }) =>
    !isOnHomePage
      ? css`
          padding: ${theme.space["space-4"]};

          & > div {
            flex: 1 0 0;

            &:not(:first-child) {
              margin-left: ${theme.space["space-4"]};
            }
          }
        `
      : css`
          justify-content: space-between;
          padding: ${theme.space["space-4"]} ${theme.space["space-4"]} 0
            ${theme.space["space-4"]};
        `}
`;

export const AmountContainer = styled.div`
  display: flex;

  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.colorTokens.content.subtle3};
  }
`;

export const AmountFlexWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
